var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tab_content_area", attrs: { id: "tab1" } }, [
    _vm._m(0),
    _c("div", { staticClass: "container_tbl_wrap" }, [
      _c("div", { staticClass: "tbl_tit" }, [
        _vm._v(_vm._s(_vm.$t("msg.NEWB010P050.003"))),
      ]),
      _vm._m(1),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "img_wrap", staticStyle: { height: "300px" } },
      [
        _c("img", {
          attrs: { src: require("../../../assets/images/trans/con_dry.png") },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tbl_wrap" }, [
      _c("table", { staticClass: "tbl_col" }, [
        _c("colgroup", [
          _c("col", { staticStyle: { width: "14%" } }),
          _c("col", { staticStyle: { width: "auto" } }),
          _c("col", { staticStyle: { width: "14%" } }),
          _c("col", { staticStyle: { width: "14%" } }),
          _c("col", { staticStyle: { width: "14%" } }),
          _c("col", { staticStyle: { width: "14%" } }),
          _c("col", { staticStyle: { width: "14%" } }),
        ]),
        _c("thead", [
          _c("tr", [
            _c("th", { attrs: { scope: "col" } }, [
              _vm._v("Type of "),
              _c("br"),
              _vm._v("Container"),
            ]),
            _c("th", { attrs: { scope: "col" } }, [
              _vm._v("Internal"),
              _c("br"),
              _vm._v("Dimension"),
              _c("br"),
              _vm._v("(mm)"),
            ]),
            _c("th", { attrs: { scope: "col" } }, [
              _vm._v("Door"),
              _c("br"),
              _vm._v("Opening"),
              _c("br"),
              _vm._v("(mm)"),
            ]),
            _c("th", { attrs: { scope: "col" } }, [
              _vm._v("Load"),
              _c("br"),
              _vm._v("Capacity"),
              _c("br"),
              _vm._v("(m"),
              _c("sup", [_vm._v("3")]),
              _vm._v(")"),
            ]),
            _c("th", { attrs: { scope: "col" } }, [
              _vm._v("Container"),
              _c("br"),
              _vm._v("Tare Weight"),
              _c("br"),
              _vm._v("(Kg)"),
            ]),
            _c("th", { attrs: { scope: "col" } }, [
              _vm._v("Max "),
              _c("br"),
              _vm._v("Pay Load"),
              _c("br"),
              _vm._v("(Kg)"),
            ]),
            _c("th", { attrs: { scope: "col" } }, [
              _vm._v("Max "),
              _c("br"),
              _vm._v("Gross Weight"),
              _c("br"),
              _vm._v("(Kg)"),
            ]),
          ]),
        ]),
        _c("tbody", [
          _c("tr", [
            _c("th", { attrs: { scope: "row" } }, [_vm._v("20GP")]),
            _c("td", [
              _vm._v("L: 5,898"),
              _c("br"),
              _vm._v("W: 2,352"),
              _c("br"),
              _vm._v("H: 2,390"),
            ]),
            _c("td", [_vm._v("W: 2,340"), _c("br"), _vm._v("H: 2,280")]),
            _c("td", [_vm._v("33.2")]),
            _c("td", [_vm._v("2,250")]),
            _c("td", [_vm._v("21,750")]),
            _c("td", [_vm._v("24,000")]),
          ]),
          _c("tr", [
            _c("th", { attrs: { scope: "row" } }, [_vm._v("40GP")]),
            _c("td", [
              _vm._v("L: 12,032"),
              _c("br"),
              _vm._v("W: 2,352"),
              _c("br"),
              _vm._v("H: 2,390"),
            ]),
            _c("td", [_vm._v("W: 2,340"), _c("br"), _vm._v("H: 2,280")]),
            _c("td", [_vm._v("67.6")]),
            _c("td", [_vm._v("3,740")]),
            _c("td", [_vm._v("26,740")]),
            _c("td", [_vm._v("30,480")]),
          ]),
          _c("tr", [
            _c("th", { attrs: { scope: "row" } }, [_vm._v("40HC")]),
            _c("td", [
              _vm._v("L: 12,032"),
              _c("br"),
              _vm._v("W: 2,352"),
              _c("br"),
              _vm._v("H: 2,695"),
            ]),
            _c("td", [_vm._v("W: 2,340"), _c("br"), _vm._v("H: 2,585")]),
            _c("td", [_vm._v("76.3")]),
            _c("td", [_vm._v("3,940")]),
            _c("td", [_vm._v("26,540")]),
            _c("td", [_vm._v("30,480")]),
          ]),
          _c("tr", [
            _c("th", { attrs: { scope: "row" } }, [_vm._v("45HC")]),
            _c("td", [
              _vm._v("L: 13,556"),
              _c("br"),
              _vm._v("W: 2,352"),
              _c("br"),
              _vm._v("H: 2,698"),
            ]),
            _c("td", [_vm._v("W: 2,340"), _c("br"), _vm._v("H: 2,585")]),
            _c("td", [_vm._v("86.00")]),
            _c("td", [_vm._v("4,720")]),
            _c("td", [_vm._v("27,780")]),
            _c("td", [_vm._v("32,500")]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }