<template>
  <div id="tab1" class="tab_content_area">
    <div class="img_wrap" style="height:300px;">
      <img src="../../../assets/images/trans/con_dry.png">
    </div>
    <div class="container_tbl_wrap">
      <div class="tbl_tit">{{ $t('msg.NEWB010P050.003') }}</div><!-- General Purpose container (GP) -->
      <div class="tbl_wrap">
        <table class="tbl_col">
          <colgroup>
            <col style="width:14%">
            <col style="width:auto">
            <col style="width:14%">
            <col style="width:14%">
            <col style="width:14%">
            <col style="width:14%">
            <col style="width:14%">
          </colgroup>
          <thead>
            <tr>
              <th scope="col">Type of <br>Container</th>
              <th scope="col">Internal<br>Dimension<br>(mm)</th>
              <th scope="col">Door<br>Opening<br>(mm)</th>
              <th scope="col">Load<br>Capacity<br>(m<sup>3</sup>)</th>
              <th scope="col">Container<br>Tare Weight<br>(Kg)</th>
              <th scope="col">Max <br>Pay Load<br>(Kg)</th>
              <th scope="col">Max <br>Gross Weight<br>(Kg)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">20GP</th>
              <td>L: 5,898<br>W: 2,352<br>H: 2,390</td>
              <td>W: 2,340<br>H: 2,280</td>
              <td>33.2</td>
              <td>2,250</td>
              <td>21,750</td>
              <td>24,000</td>
            </tr>
            <tr>
              <th scope="row">40GP</th>
              <td>L: 12,032<br>W: 2,352<br>H: 2,390</td>
              <td>W: 2,340<br>H: 2,280</td>
              <td>67.6</td>
              <td>3,740</td>
              <td>26,740</td>
              <td>30,480</td>
            </tr>
            <tr>
              <th scope="row">40HC</th>
              <td>L: 12,032<br>W: 2,352<br>H: 2,695</td>
              <td>W: 2,340<br>H: 2,585</td>
              <td>76.3</td>
              <td>3,940</td>
              <td>26,540</td>
              <td>30,480</td>
            </tr>
            <tr>
              <th scope="row">45HC</th>
              <td>L: 13,556<br>W: 2,352<br>H: 2,698</td>
              <td>W: 2,340<br>H: 2,585</td>
              <td>86.00</td>
              <td>4,720</td>
              <td>27,780</td>
              <td>32,500</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContainerDry'
}
</script>

<style scoped>
</style>
